import {graphql} from "./types";

export default graphql(`
    mutation AdminUpdateAccount($input: AdminUpdateAccountInput!) {
        adminUpdateAccount(input: $input) {
            account {
                nodeId
                id
                firstName
                lastName
                contacts {
                    nodes {
                        nodeId
                        id
                        type
                        value
                    }
                }
                
                provider {
                    nodeId
                    name
                    email
                }
            }
        }
    }
`);