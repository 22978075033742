import React, {useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import FuzzySet from "fuzzyset.js";

import {fragments, providerByIdQueryDocument, providerBySlugQueryDocument, updateProviderMutationDocument} from '../gql/Provider';
import styled from "styled-components";
import {useFragment} from "../gql/types";
import {
    ProviderDataFragment,
    ProviderPatch, UpdateProviderDocument,
    UpdateProviderMutation,
    UpdateProviderMutationVariables
} from "../gql/types/graphql";

interface EditableProps {
    type?: string,
    value: string|null,
    onChange?: (value:string) => void,
    placeholder?: string
}

interface RowProps extends EditableProps {
    label: string
}

const Cell = styled.td`

`;

const Row = ({label, ...props}:RowProps) => (
    <tr>
        <th>{label}:</th>
<Cell><Editable {...props}/></Cell>
</tr>
);

const Value = styled.div`
  cursor: pointer;
`;

const Placeholder = styled.span`
  color: #aaa;
`;

export const Editable = ({type, value:originalValue, onChange, placeholder}: EditableProps) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(originalValue);

    if (editing) {
        const save = () => {
            if (onChange)
                onChange(value || '');

            setEditing(false);
        };

        const handler = (e:any) => {
            switch (e.keyCode) {
                case 27:
                    setValue(originalValue);
                    setEditing(false);
                    break;
                case 13:
                    if (!(e.shiftKey || e.ctrlKey)) {
                        e.preventDefault();
                        save();
                    }
                    break;
                default:
                //console.log(e.shiftKey);
            }
        };

        if (type === 'textarea') {
            return (
                <textarea
                    value={value || ''}
            onChange={e => setValue(e.target.value)}
            onKeyDown={handler}
            onBlur={save}
            autoFocus={true}
            placeholder={placeholder}
            />
        )
        }

        return (
            <input
                type={type}
        value={value||''}
        onChange={e => setValue(e.target.value)}
        onKeyDown={handler}
        onBlur={save}
        autoFocus={true}
        placeholder={placeholder}
        />
    );
    }
    else {
        const handler = () => {
            if (onChange)
                setEditing(true);
        };

        // &nbsp; is \u00A0
        return (
            <Value onClick={handler}>{value || (placeholder && <Placeholder>{placeholder}</Placeholder>) || '\u00A0'}</Value>
    );
    }
    };

    export default function useProvider(id:string) {
    const {provider} = useProviderById(id);

    const [updateProviderMutation] = useMutation(updateProviderMutationDocument);

    if (!provider)
        return {};

    const {nodeId,info} = provider;

    const updateProvider = (patch:any) => updateProviderMutation({
        variables: {
            input: {
                nodeId,
                patch
            }
        }
    });

    const updateProviderInfo = (map:any) => updateProvider({info: {...(info||{}), ...map}});

    const row = (object:any, label:string, type='text') => {
        let key = label.replace(/\W+/, '');
        key = key.charAt(0).toLowerCase() + key.substr(1);
        const value = object[key];

        const onChange = (value:string) => {
            const params = {[key]:value};

            if (object === provider)
                updateProvider(params).then();
            else
                updateProviderInfo(params).then();
        };

        return (
            <Row label={label} value={value} onChange={onChange} type={type}/>
    )
    };

    return {
        provider,
        updateProvider,
        updateProviderInfo,
        row
    };
}

export function useProviderById(id:string) {
    const {data, loading, error} = useQuery(providerByIdQueryDocument, {
        variables: {
            id
        }
    });

    const provider = useFragment(fragments.provider, data && data.providerById);

    return {loading, provider, error};
}

export function useProviderBySlug(slug:string) {
    const {data, loading, error} = useQuery(providerBySlugQueryDocument, {
        variables: {
            slug
        }
    });

    const provider = data && data.providerBySlug;

    return {loading, provider, error};
}

export function useUpdateProvider() {
    const [updateProviderMutation] = useMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(UpdateProviderDocument);

    return (nodeId:string, patch:ProviderPatch) => updateProviderMutation({
        variables: {
            input: {
                nodeId,
                patch
            }
        }
    });
}

export function getAgentStats(agent: ProviderDataFragment) {
    if (!agent)
        return {};

    const year = new Date().getFullYear()
    const transactionCount = agent.transactionHistory.length;
    const years = year - Number(agent.info.licensedSince || year);
    const average = Math.round(agent.transactionHistory.reduce((total:number, {soldPrice:price}:any) => total + price, 0) / transactionCount) || 0;
    const averagePrice = (average > 1000000) ? `$${Number(average/1000000).toFixed(2)}M` : `$${Math.round(average/1000)}K`;

    return {transactionCount, years, averagePrice};
}

function fuzzyScore(provider:ProviderDataFragment|undefined|null, array:Array<string>) {
    if (!provider)
        return 0;

    return (array || []).reduce((score:number, name:string) => {
        const fuzzySet = FuzzySet();
        fuzzySet.add(provider.name);
        const result = fuzzySet.get(name);

        const s = (result && result[0][0]) || 0;

        return Math.max(score, s)
    }, 0)
}

export function getTransactionSide(provider:ProviderDataFragment|undefined|null, transaction:any) {
    if (!provider)
        return 'Unknown';

    const listingScore = fuzzyScore(provider, transaction.listingAgents);
    const sellingScore = fuzzyScore(provider, transaction.sellingAgents);

    if (listingScore < .5 && sellingScore < .5)
        return 'Unknown';

    if (listingScore > sellingScore)
        return 'Seller';
    else
        return 'Buyer';
}

