import {graphql} from './types';

export const fragments = {
    consumerData: graphql(`
        fragment ConsumerData on Consumer {
              nodeId
              id
              vendors
              createdAt
              engagebay
              account {
                nodeId
                id
                firstName
                lastName
                suppressMessages
                contacts {
                  nodes {
                    nodeId
                    type
                    value
                    verifiedAt
                    undeliverableAt
                  }
                }
                campaign
                invitations {
                    nodes {
                        firstName
                        lastName
                        phone
                        email
                        createdAt
                    }
                }
                pointsAvailable
                pointsLedger(orderBy: CREATED_AT_DESC) {
                    nodes {
                        nodeId
                        id
                        entryType
                        quantity
                        note
			            data
                        createdAt
                        accountByCreatedBy {
                          firstName
                          lastName
                          nodeId
                          id
                        }
                    }
                }
                  accountByReferredBy {
                        nodeId
                        firstName
                        lastName
                      contacts(condition: {type: "email"}) {
                        nodes {
                          nodeId
                          type
                          value
                        }
                      }
                  }
                deletedAt
              }
              inquiries {
                nodes {
                    nodeId
                    id
                    details
                    detailsConfirmed
                    providers
                    flags
                    leads {
                        nodes {
                            nodeId
                            ebDealUniqueId
                            provider {
                                nodeId
                                name
                                phone
                                email
                            }
                        }
                    }
                }
              }
              providersInArea
              consultation
              deletedAt
        }
    `)
};

export default graphql(`
query ConsumerById($id: UUID!) {
    consumerById(id: $id) {
              nodeId
              id
              vendors
              createdAt
              engagebay
              account {
                nodeId
                id
                firstName
                lastName
                suppressMessages
                contacts {
                  nodes {
                    nodeId
                    type
                    value
                    verifiedAt
                    undeliverableAt
                  }
                }
                campaign
                invitations {
                    nodes {
                        firstName
                        lastName
                        phone
                        email
                        createdAt
                    }
                }
                pointsAvailable
                pointsLedger(orderBy: CREATED_AT_DESC) {
                    nodes {
                        nodeId
                        id
                        entryType
                        quantity
                        note
			            data
                        createdAt
                        accountByCreatedBy {
                          firstName
                          lastName
                          nodeId
                          id
                        }
                    }
                }
                deletedAt
              }
              inquiries {
                nodes {
                    nodeId
                    id
                    details
                    detailsConfirmed
                    providers
                    flags
                    inquiryAddresses {
                        nodes {
                          nodeId
                          id
                          address
                          price
                          latitude
                          longitude
                          ebDealUniqueId
                        }
                    }
                    leads {
                        nodes {
                            nodeId
                            ebDealUniqueId
                            provider {
                                nodeId
                                id
                                name
                                title
                                phone
                                email
                                image {
                                    url
                                }
                                account {
                                    id
                                    firstName
                                    lastName
                                }
                                engagebay
                            }
                        }
                    }
                }
              }
              providersInArea
              consultation
              deletedAt
    }
}
`);

export const updateConsumerMutationDocument = graphql(`
    mutation UpdateConsumer($input: UpdateConsumerInput!) {
      updateConsumer(input: $input) {
        consumer {
          nodeId
          engagebay
          consultation
        }
      }
    }
`);
